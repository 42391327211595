import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { MatIconModule } from '@angular/material/icon';
import { NgProgressbar } from 'ngx-progressbar';
import { NgProgressRouter } from 'ngx-progressbar/router';
import { LineBreaksPipe } from './pipes/line-break.pipe';

@NgModule({
  declarations: [NavigationBarComponent, LineBreaksPipe],
  imports: [CommonModule, MatIconModule, NgProgressbar, NgProgressRouter],
  exports: [NavigationBarComponent, LineBreaksPipe],
})
export class SharedModule {}
